import { lazy, Suspense, useEffect } from "react";
import { buildConfig } from "./config";
import { ThirdPartyRouter, foldThirdPartyLocation } from "./thirdPartyRouter";
import { constNull } from "fp-ts/function";
import { AnonymousAuthProvider } from "./AnonymousAuthProvider";
import { BuildConfigProvider } from "./BuildConfigProvider";
import { ThirdPartyAppProvider } from "./ThirdPartyAppProvider";
import { option } from "fp-ts";
import { useLibraryInfo } from "design-system";

const ThirdPartyLanding = lazy(() => import("./3PLanding/ThirdPartyLanding"));

const ThirdPartyResetPassword = lazy(
  () => import("./3PLogin/ChangePasswordAndLoginAsThirdParty")
);

const Mortgage = lazy(() => import("./Mortgage/Mortgage"));

const handleExit = () => {
  window.location.href = window.origin;
};

export function ThirdPartyApp() {
  const { getVersion } = useLibraryInfo();
  useEffect(() => {
    console.info(`Omnichannel Design System v${getVersion()}`);
  }, []);

  return (
    <BuildConfigProvider
      apiEndpoint={buildConfig.apiEndpoint}
      apiPort={buildConfig.apiPort}
    >
      <ThirdPartyRouter
        render={foldThirdPartyLocation({
          Home: () => (
            <ThirdPartyAppProvider
              showPasswordWasResetMessage={false}
              authInfo={option.none}
            >
              {passwordWasChanged => (
                <Suspense fallback={constNull}>
                  <ThirdPartyLanding passwordWasChanged={passwordWasChanged} />
                </Suspense>
              )}
            </ThirdPartyAppProvider>
          ),
          ThirdPartyResetPassword: ({ id }) => (
            <AnonymousAuthProvider channel="3P_InPerson">
              <Suspense fallback={constNull}>
                <ThirdPartyResetPassword id={id} />
              </Suspense>
            </AnonymousAuthProvider>
          ),
          OpenApplication: ({ encryptedApplicationId }) => (
            <ThirdPartyAppProvider
              showPasswordWasResetMessage={false}
              authInfo={option.none}
            >
              {_ => (
                <Suspense fallback={constNull}>
                  <Mortgage
                    isThirdParty
                    existing
                    openFromLink
                    applicationId={encryptedApplicationId}
                    onExit={handleExit}
                    isExistingClient={false}
                  />
                </Suspense>
              )}
            </ThirdPartyAppProvider>
          ),
        })}
      />
    </BuildConfigProvider>
  );
}
