import { UUID } from "io-ts-types/lib/UUID";
import { lit, type, zero } from "fp-ts-routing";
import { parseLocation } from "./parseLocation";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

interface Home {
  readonly _tag: "Home";
}

interface ThirdPartyResetPassword {
  readonly _tag: "ThirdPartyResetPassword";
  readonly id: UUID;
}

interface OpenApplication {
  readonly _tag: "OpenApplication";
  readonly encryptedApplicationId: NonEmptyString;
}

export type ThirdPartyLocation =
  | Home
  | ThirdPartyResetPassword
  | OpenApplication;

export const home: ThirdPartyLocation = { _tag: "Home" };

export const thirdPartyResetPassword = (
  params: Omit<ThirdPartyResetPassword, "_tag">
): ThirdPartyLocation => ({
  ...params,
  _tag: "ThirdPartyResetPassword",
});

export const openApplication = (
  params: Omit<OpenApplication, "_tag">
): ThirdPartyLocation => ({
  ...params,
  _tag: "OpenApplication",
});

export function foldThirdPartyLocation<R>(
  matches: {
    [L in ThirdPartyLocation["_tag"]]: (
      args: Omit<Extract<ThirdPartyLocation, { _tag: L }>, "_tag">
    ) => R;
  }
): (location: ThirdPartyLocation) => R {
  return location => matches[location._tag](location as any);
}

const thirdPartyResetPasswordMatch = lit("broker")
  .then(lit("reset"))
  .then(type("id", UUID));

const openApplicationMatch = lit("openapplication").then(
  type("encryptedApplicationId", NonEmptyString)
);

const router = zero<ThirdPartyLocation>()
  .alt(thirdPartyResetPasswordMatch.parser.map(thirdPartyResetPassword))
  .alt(openApplicationMatch.parser.map(openApplication));

type Props = {
  render: (location: ThirdPartyLocation) => JSX.Element;
};

export function ThirdPartyRouter(props: Props) {
  const location = parseLocation(
    window.location.pathname + window.location.search,
    router,
    home
  );

  return props.render(location);
}
