import { setupLogging } from "./logger";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "design-system/lib/index.css";
import { ThirdPartyApp } from "./ThirdPartyApp";

setupLogging();

ReactDOM.render(
  <StrictMode>
    <ThirdPartyApp />
  </StrictMode>,
  document.getElementById("root")
);
